import { Box } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as Logo } from './assets/logo.svg';
import FileInput from './FileInput';
import Header from './Header';
import { createJob, JobStatus, pollJob } from './api/api';
import ProgressSpinner from './ProgressSpinner';
import Download from './Download';

const App: React.FC = () => {
  const [jobID, setJobID] = useState<string | undefined>(undefined);
  const [jobStatus, setJobStatus] = useState<JobStatus | undefined>(undefined);
  const [jobTimer, setJobTimer] = useState<
    ReturnType<typeof setTimeout> | undefined
  >(undefined);

  useEffect(() => {
    if (jobTimer) {
      clearTimeout(jobTimer);
    }
    setJobTimer(setTimeout(poll, 1000));
  }, [jobID]);

  const poll = useCallback(() => {
    if (!jobID) {
      if (jobTimer) {
        clearTimeout(jobTimer);
        setJobTimer(undefined);
      }
      return;
    }
    pollJob({ id: jobID })
      .then((result) => {
        setJobStatus(result.status);
        console.log('result.status', result.status);
        if (result.status === 'pending') {
          setJobTimer(setTimeout(poll, 1000));
          return;
        }
        setJobTimer(undefined);
      })
      .catch((err) => {
        console.error('error: ', err);
      });
  }, [jobTimer, setJobTimer, jobID]);

  const onProcess = useCallback(
    (files: File[]) => {
      console.log('files', files);
      setJobStatus('pending');
      createJob(files[0])
        .then((result) => {
          setJobID(result.id);
        })
        .catch((err) => {
          /* TODO: show error*/
          setJobStatus(undefined);
          setJobID(undefined);
          console.error('error: ', err);
        });
    },
    [jobTimer, poll],
  );

  return (
    <Box
      display="flex"
      width="100vw"
      height="calc(100vh - 80px)"
      alignItems="flex-start"
      justifyContent="center"
      paddingTop="80px"
    >
      <Box
        display="flex"
        width="1100px"
        height="65vh" //behöver modifieras, funkar inte så bra vid 100% scaling
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Box width="330px" height="100px">
            <Logo />
          </Box>
        </Box>
        <Box display="flex" width="100%" height="550px" flexDirection="column">
          <Header status={jobStatus} />
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              backgroundColor: 'secondary.main',
              borderRadius: '0 0 15px 15px',
              alignItems: 'center',
              justifyContent: 'center',
              border: ({ palette }) =>
                jobStatus === 'failure'
                  ? `2px inset ${palette.error.main}`
                  : 'none',
            }}
          >
            {!jobStatus && <FileInput onProcess={onProcess} />}
            {jobStatus === 'pending' && <ProgressSpinner />}
            <Download
              jobStatus={jobStatus}
              jobID={jobID}
              setJobID={setJobID}
              setJobStatus={setJobStatus}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default App;
