import React, { FC } from 'react';
import { Box, CircularProgress, Fade, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const ProgressSpinner: FC = () => {
  return (
    <Fade in={true}>
      <Box
        display="flex"
        flexGrow={1}
        flexBasis={0}
        flexDirection="column"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Typography
          variant="h4"
          color="secondary.contrastText"
          fontWeight="400"
          fontFamily="Roboto"
        >
          <FormattedMessage defaultMessage="Processing your request..." />
        </Typography>
        <CircularProgress size={85} />
      </Box>
    </Fade>
  );
};

export default ProgressSpinner;
