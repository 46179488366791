import axios from 'axios';
import config from '../config';

export type JobStatus = 'pending' | 'success' | 'failure';

interface JobCreateResponse {
  id: string;
}

interface JobPollResponse {
  status: JobStatus;
}

type JobPollRequest = JobCreateResponse;

export const pollJob = async (
  req: JobPollRequest,
): Promise<JobPollResponse> => {
  const response = await axios.get<JobPollResponse>(
    `${config.apiBaseURL}/jobs/${req.id}`,
  );
  return response.data;
};

export const createJob = async (file: File): Promise<JobCreateResponse> => {
  const form = new FormData();
  form.append('file', file);
  const response = await axios.post<JobCreateResponse>(
    `${config.apiBaseURL}/jobs`,
    form,
  );

  return response.data;
};
