import { Box, Button, Typography } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { JobStatus } from './api/api';
import config from './config';

interface DownloadProps {
  jobStatus?: JobStatus;
  jobID?: string;
  setJobStatus: (status: undefined) => void;
  setJobID: (id: undefined) => void;
}

const Download: FC<DownloadProps> = ({
  jobStatus,
  jobID,
  setJobStatus,
  setJobID,
}) => {
  const onClick = useCallback(() => {
    if (!jobID) {
      return;
    }
    window.open(`${config.apiBaseURL}/jobs/${jobID}/result`, '_blank');
  }, [jobID]);

  const clearJob = useCallback(() => {
    setJobStatus(undefined);
    setJobID(undefined);
  }, [setJobID, setJobStatus]);

  if (jobStatus !== 'success' && jobStatus !== 'failure') {
    return null;
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 6,
        flexBasis: 0,
        flexDirection: 'column',
        justifyContent: 'space-evenly',
      }}
    >
      <Typography
        variant="h4"
        color="secondary.contrastText"
        fontWeight="400"
        fontFamily="Roboto"
      >
        {jobStatus === 'success' && (
          <FormattedMessage defaultMessage="Your requested file is now ready to download." />
        )}
        {jobStatus === 'failure' && (
          <FormattedMessage defaultMessage="Processing failed. Download error log?" />
        )}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          alignItems: 'normal',
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={clearJob}
        >
          <FormattedMessage defaultMessage="Back" />
        </Button>
        {jobStatus === 'success' && (
          <Button
            onClick={onClick}
            variant="contained"
            color="primary"
            size="large"
          >
            <FormattedMessage defaultMessage="Download" />
          </Button>
        )}
        {jobStatus === 'failure' && (
          <Button
            onClick={onClick}
            variant="contained"
            color="secondary"
            size="large"
          >
            <FormattedMessage defaultMessage="Download" />
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default Download;
