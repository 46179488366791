import React, { FC } from 'react';
import { createTheme, ThemeProvider as ThemeP } from '@mui/material';

/*Might set default typography-stuff too. */

export const theme = createTheme({
  palette: {
    primary: {
      main: '#0091d3',
      contrastText: '#f5f5f5',
    },
    secondary: {
      main: '#ececec',
      contrastText: '#323e46',
    },
    error: {
      main: '#FF0000',
    },
  },
});

const ThemeProvider: FC = ({ children }) => {
  return <ThemeP theme={theme}>{children}</ThemeP>;
};

export default ThemeProvider;
