import React, { FC, useState } from 'react';
import { DropzoneArea } from 'mui-file-dropzone';
import { Box, Button } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import FileUploadIcon from '@mui/icons-material/FileUpload';

interface FileInputProps {
  onProcess: (file: File[]) => void;
}

const FileInput: FC<FileInputProps> = ({ onProcess }) => {
  const intl = useIntl();
  const [files, setFiles] = useState<File[]>([]);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        '.dropzone': {
          background: 'none',
          position: 'relative',
          flexGrow: 6,
          flexBasis: 0,
          borderRadius: '0 0 15px 15px',
          border: ({ palette }) => `2px dashed ${palette.primary.main}`,
          'div:nth-of-type(2)': { justifyContent: 'center', marginTop: '5%' },
        },
        '.dropzoneParagraph': {
          fontWeight: '400',
          color: 'secondary.contrastText',
        },
      }}
    >
      <DropzoneArea
        dropzoneClass="dropzone"
        dropzoneParagraphClass="dropzoneParagraph"
        Icon={FileUploadIcon}
        fileObjects={[]}
        acceptedFiles={['.txt', '.csv']}
        filesLimit={1}
        dropzoneText={intl.formatMessage({
          defaultMessage:
            'Click to select or drag-and-drop Revit config file (*.txt / *.csv)',
        })}
        showFileNames
        showFileNamesInPreview
        showPreviews={false}
        showPreviewsInDropzone
        alertSnackbarProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          color: '#ff0000',
        }}
        onChange={(files) => setFiles(files)}
      />
      <Box display="flex" flexGrow={1} flexBasis={0} alignItems="center">
        <Button
          disabled={files.length === 0}
          variant="contained"
          color="primary"
          onClick={() => onProcess(files)}
        >
          <FormattedMessage
            defaultMessage="Process"
            description="Process button"
          />
        </Button>
      </Box>
    </Box>
  );
};

export default FileInput;
