import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { JobStatus } from './api/api';

interface HeaderProps {
  status?: JobStatus;
}

const Header: FC<HeaderProps> = ({ status }) => {
  return (
    <Box
      sx={{
        backgroundColor: ({ palette }) =>
          !status || status !== 'failure'
            ? `${palette.primary.main}`
            : `${palette.error.main}`,
        height: '90px',
        borderRadius: '15px 15px 0 0 ',
        paddingLeft: '24px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Typography variant="h4" color="primary.contrastText" fontWeight="500">
        <FormattedMessage defaultMessage="Revit 3D Extractor" />
      </Typography>
    </Box>
  );
};

export default Header;
