import React, { FC, useMemo } from 'react';
import { IntlProvider, MessageFormatElement } from 'react-intl';
import messages_sv from '../translations/sv.json';
import messages_en from '../translations/en.json';

const language = navigator.language.split(/[-_]/)[0];

const LocaleProvider: FC = ({ children }) => {
  const messages = useMemo<Record<string, MessageFormatElement[]>>(() => {
    if (language === 'sv') {
      return messages_sv as Record<string, MessageFormatElement[]>;
    }
    return messages_en as Record<string, MessageFormatElement[]>;
  }, []);

  return (
    <IntlProvider locale={language} defaultLocale="en" messages={messages}>
      {children}
    </IntlProvider>
  );
};

export default LocaleProvider;
